<template>
    <div class="issues-view-modal">
        <ModalContainer :title="$t('issues.view_issue')" identifier="issues-view-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputSelect v-model="$v.issue.license_plate.$model" identifier="license_plate"
                                 :label="$t('issues.license_plate')" :options="vehicleOptions"
                                 :placeholder="$t('issues.license_plate')"
                                 :has-error="$v.issue.license_plate.$error" track-by="id" :disabled="true"
                                 :display-custom-label="(row) => `${row.attributes.license_plate} - ${row.relationships.brand.data.attributes.name} - ${row.attributes.model}`">
                    <template v-slot:errors>
                        <p v-if="!$v.issue.drop_off_location.required">
                            {{ $t('validation.x_are_required', {x: $t('issues.drop_off_location')}) }}
                        </p>
                    </template>
                </FormInputSelect>

                <FormInputText v-model="$v.issue.issue_description.$model" identifier="issue_description" :label="$t('issues.issue')"
                               :placeholder="$t('issues.issue')" :disabled="true"
                               :has-error="$v.issue.issue_description.$error" :use-textarea="true" :large-textarea="true">
                    <template v-slot:errors>
                        <p v-if="!$v.issue.issue_description.required">
                            {{$t('validation.x_is_required',{x: $t('issues.issue')})}}
                        </p>
                    </template>
                </FormInputText>


                <div class="buttons-container">
                    <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                        {{$t('close')}}
                    </Button>

                    <div class="status-select-container">
                        <p>{{ $t('vehicles.status') }}</p>
                        <FormInputSelect v-model="vehicle_status" :placeholder="$t('vehicles.status')"  :disabled="true"
                                         identifier="vehicle_status" track-by="name" :display-custom-label="(row) => `${row.name}`"/>
                    </div>
                </div>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import FormInputDateTime from "../form/FormInputDateTime";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import _ from "lodash";
export default {
    name: "IssuesViewModal",
    components: {FormInputDateTime, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            issue: {
                license_plate: null,
                issue_description: null,
            },
            is_saving: false,
            is_loading: false,
            original: null,
            is_loading_vehicles: false,
            vehicleOptions: [],
            vehicle_status: null,
        }
    },
    validations: {
        issue: {
            license_plate: {required},
            issue_description: {required},
        }
    },
    props: {
        issue_id: {
            type: Number,
            required: true,
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('issues-view-modal', status);
        },

        populate(){
            if (!this.original) return;

            this.$v.issue.license_plate.$model =  _.find(this.vehicleOptions, {id: this.original.relationships.vehicle.data.id});
            this.$v.issue.issue_description.$model = this.original.attributes.comment;
            this.vehicle_status = {name: this.original.attributes.status.charAt(0).toUpperCase() + this.original.attributes.status.slice(1), value: this.original.attributes.status}
        },

        save() {
            this.close(true);
        },

        async retrieveOriginalIssue() {
            this.is_loading = true

            await this.$axios.get(`issues/${this.issue_id}`)
                .then(({data}) => {
                    this.original = data.data
                    this.is_loading = false
                })
                .catch(e => {
                    this.is_loading = false

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('issues.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        async retrieveVehiclesOptions() {
            this.is_loading_vehicles = false;
            await this.$axios.get('vehicles/list')
                .then(({data}) => {
                    this.vehicleOptions = data.data;
                    this.is_loading_vehicles = false;
                })
                .catch(e => {
                    this.is_loading_vehicles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('vehicles.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },

    async mounted() {
        await this.retrieveOriginalIssue();
        await this.retrieveVehiclesOptions();
        this.populate();
    }
}
</script>

<style lang="scss" scoped>
.buttons-container{
    @apply flex flex-row justify-between;

    .status-select-container {
        @apply flex flex-row items-center;

        p {
            @apply mr-4 text-xs;
        }

        .input-group {
            @apply mb-0 w-40 mr-4;
        }
    }

}

</style>