<template>
    <div class="confirm-modal">
        <ModalContainer :title="$t('vehicles.delete_brand')" identifier="vehicles-brand-delete-modal" :closable="true">
            <div class="sure-container">
                <p>{{$t('vehicles.prompt_delete_brand')}}</p>

                <div class="buttons">
                    <Button :className="`--secondary --outline wider`" :onclick="back">{{$t('cancel')}}</Button>
                    <Button :className="`--primary wider`" :onclick="deleteBrand">{{$t('delete')}}</Button>
                </div>
            </div>
        </ModalContainer>
    </div>
</template>

<script>
import Button from "../Button";
import ModalContainer from "../modal/ModalContainer";
import VehiclesBrandIndexModal from "./VehiclesBrandIndexModal";

export default {
    name: 'VehiclesBrandDeleteModal',
    components: {ModalContainer, Button},
    props: {
        brand_id: {
            type: Number,
            required: true,
        }
    },

    methods: {
        close(status) {
            this.$modal.hide('vehicles-brand-delete-modal', status);
        },

        back(){
            this.close(false);
            this.$modal.show(
                VehiclesBrandIndexModal, {},
                {
                    name: 'vehicles-brand-index-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                    }
                }
            );
        },

        deleteBrand(){
            this.is_deleting = true;
            this.$axios.delete(`brands/${this.brand_id}`)
                .then(({data}) => {
                    this.is_deleting = false;
                    this.close(false);
                    this.$modal.show(
                        VehiclesBrandIndexModal, {},
                        {
                            name: 'vehicles-brand-index-modal',
                            adaptive: true,
                            resizable: true,
                            height: 'auto',
                            scrollable: true,
                            classes: 'modal',
                        }, {
                            'before-close': (e) => {
                            }
                        }
                    );
                })
                .catch(e => {
                    this.is_deleting = false;
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('brands.error_delete')),
                        type: 'error',
                    });
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.confirm-modal {
    .sure-container {
        @apply flex flex-col items-center mt-6;

        p {
            @apply font-bold text-black text-sm mb-6;
        }

        .buttons {
            @apply flex flex-row justify-center;

            button {
                @apply mx-2 mt-0 px-8 py-3;
            }
        }
    }

    .bottom-text {
        p {
            @apply text-xs text-grey-dark italic text-center mt-8 leading-loose;
        }
    }
}
</style>
