<template>
    <div class="vehicle-status-unavailable-modal">
        <ModalContainer :title="$t('vehicles.status_change')" identifier="vehicle-status-unavailable-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">

                <p class="text-description">{{ $t('vehicles.status_change_description') }}</p>


                <FormInputSelect v-model="$v.issue.issues_to_fix.$model" identifier="issues"
                                 :label="$t('issues.issues')" :options="issuesOptions"
                                 :placeholder="$t('issues.issues')" :disabled="is_saving"
                                 :has-error="$v.issue.issues_to_fix.$error" track-by="id" :multiple="true"
                                 :display-custom-label="(row) => `${row.attributes.comment}`">
                    <template v-slot:errors>
                        <p v-if="!$v.user.role.required">
                            {{$t('validation.x_is_required',{x: $t('users.role')})}}
                        </p>
                    </template>
                </FormInputSelect>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('submit')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import FormInputDateTime from "../form/FormInputDateTime";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
export default {
    name: "VehicleStatusUnavailableModal",
    components: {FormInputDateTime, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            issue: {
                issues_to_fix: [],
            },
            is_saving: false,
            is_loading_issues: false,
            issuesOptions: [],
        }
    },
    validations: {
        issue: {
            issues_to_fix: {required},
        }
    },
    props: {
        vehicle_id: {
            type: Number,
            required: true,
        },
    },
    methods: {
        close(status) {
            this.$modal.hide('vehicle-status-unavailable-modal', status);
        },

        save() {
            this.$v.issue.$touch();
            if (this.$v.issue.$anyError || this.is_saving) return;
            this.is_saving = true;

            const payload = Object.assign({}, this.$v.issue.$model);
            payload.issues_to_fix = payload.issues_to_fix.map(issue => issue.id)
            payload.status = 'unavailable'

            this.$axios.post(`vehicles/${this.vehicle_id}/update-status`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('issues.success_updated_vehicle'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('issues.error_updated_vehicle')),
                    type: 'error',
                });
            });
        },

        async retrieveIssuesOptions(){
            this.is_loading_issues = false;
            this.$axios.get(`vehicles/${this.vehicle_id}/issues`)
                .then(({data}) => {
                    this.issuesOptions = data.data;
                    this.is_loading_issues = false;
                })
                .catch(e => {
                    this.is_loading_issues = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('issues.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },

    async mounted(){
        await this.retrieveIssuesOptions();
    }
}
</script>

<style lang="scss" scoped>
.form-container{
    @apply flex flex-col items-center;

    .text-description {
        @apply mb-5 text-center;
    }

    .input-group{
        width:72%;
    }
}


</style>