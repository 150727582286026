<template>
    <div class="input-group" :class="{inline: inlineInput}">
        <label v-if="label" :for="identifier">{{label}}</label>
        <VueCtkDateTimePicker :value="value" @input="(v)=>$emit('input', v)" :label="placeholder" :id="identifier"
                              :disabled="disabled" :error="hasError" color="#5B4A27" button-color="#5B4A27"
                              :minute-interval="minuteInterval" :only-time="onlyTime" :only-date="onlyDate"
                              :noClearButton="noClearButton" :no-button-now="noButtonNow" :min-date="minDate"
                              :max-date="maxDate" :output-format="outputFormat" :format="format"
                              :formatted="formatted" :class="className" :no-value-to-custom-elem="true"/>
        <FormErrors v-if="hasError">
            <slot name="errors"></slot>
        </FormErrors>
    </div>
</template>

<script>
    import FormErrors from "./FormErrors";

    export default {
        name: "FormInputDateTime",
        components: {FormErrors},
        props: {
            value: {
                type: String,
            },
            identifier: {
                type: String,
                default: null,
            },
            inlineInput: {
                type: Boolean,
                default: false,
            },
            className: {
                type: String,
            },
            label: {
                type: String,
                default: null,
            },
            placeholder: {
                type: String,
                default: null
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            hasError: {
                type: Boolean,
                default: false
            },
            minuteInterval: {
                type: Number,
                default: 1,
            },
            onlyTime: {
                type: Boolean,
                default: false,
            },
            onlyDate: {
                type: Boolean,
                default: false,
            },
            noClearButton: {
                type: Boolean,
                default: false
            },
            noButtonNow: {
                type: Boolean,
                default: false
            },
            minDate: {
                type: String,
            },
            maxDate: {
                type: String
            },
            outputFormat: {
                type: String,
                default: 'YYYY-MM-DD HH:mm'
            },
            format: {
                type: String,
                default: 'YYYY-MM-DD HH:mm'
            },
            formatted: {
                type: String,
                default: 'llll'
            }
        },
    }
</script>

<style lang="scss" scoped>
    .input-group {
        @apply w-full flex flex-col items-start mb-5;

        label {
            @apply text-sm text-black font-bold mb-3;
        }

        &.inline {
            @apply flex-row items-center;

            label {
                @apply text-xs font-medium mb-0 mr-4 flex-none;
            }
        }
    }
</style>