<template>
    <div class="users-create-modal">
        <ModalContainer :title="$t('vehicles.add_vehicle')" identifier="vehicles-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputText v-model="$v.vehicle.license_plate.$model" identifier="license_plate" :label="$t('vehicles.license_plate')"
                                   :placeholder="$t('vehicles.license_plate')" :disabled="is_saving"
                                   :has-error="$v.vehicle.license_plate.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.vehicle.license_plate.required">
                                {{$t('validation.x_is_required',{x: $t('vehicles.license_plate')})}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputSelect v-model="$v.vehicle.brand.$model" identifier="brand"
                                     :label="$t('vehicles.brand')" :options="brandOptions"
                                     :placeholder="$t('vehicles.brand')" :disabled="is_saving"
                                     :has-error="$v.vehicle.brand.$error" track-by="id"
                                     display-label="name">
                        <template v-slot:errors>
                            <p v-if="!$v.vehicle.brand.required">
                                {{ $t('validation.x_are_required', {x: $t('vehicles.brand')}) }}
                            </p>
                        </template>
                    </FormInputSelect>
                </FormGroupTwo>
                <FormGroupTwo>
                    <FormInputText v-model="$v.vehicle.model.$model" identifier="model" :label="$t('vehicles.model')"
                                   :placeholder="$t('vehicles.model')" :disabled="is_saving"
                                   :has-error="$v.vehicle.model.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.vehicle.model.required">
                                {{$t('validation.x_is_required',{x: $t('vehicles.model')})}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.vehicle.transmission.$model" identifier="transmission" :label="$t('vehicles.transmission')"
                                   :placeholder="$t('vehicles.transmission')" :disabled="is_saving"
                                   :has-error="$v.vehicle.transmission.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.vehicle.transmission.required">
                                {{$t('validation.x_is_required',{x: $t('vehicles.transmission')})}}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>
                <FormGroupTwo>
                    <FormInputText v-model="$v.vehicle.fuel_type.$model" identifier="fuel_type" :label="$t('vehicles.fuel_type')"
                                   :placeholder="$t('vehicles.fuel_type')" :disabled="is_saving"
                                   :has-error="$v.vehicle.fuel_type.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.vehicle.fuel_type.required">
                                {{$t('validation.x_is_required',{x: $t('vehicles.fuel_type')})}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.vehicle.seats.$model" identifier="seats" :label="$t('vehicles.seats')"
                                   :placeholder="$t('vehicles.seats')" :disabled="is_saving"
                                   :has-error="$v.vehicle.seats.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.vehicle.seats.required">
                                {{$t('validation.x_is_required',{x: $t('vehicles.seats')})}}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>
                <FormInputText v-model="$v.vehicle.description.$model" identifier="description" :label="$t('vehicles.description')"
                               :placeholder="$t('vehicles.description')" :disabled="is_saving"
                               :has-error="$v.vehicle.description.$error" :use-textarea="true" :large-textarea="true">
                    <template v-slot:errors>
                        <p v-if="!$v.vehicle.description.required">
                            {{$t('validation.x_is_required',{x: $t('vehicles.description')})}}
                        </p>
                    </template>
                </FormInputText>
                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('add')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "VehiclesCreateModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            vehicle: {
                license_plate: null,
                brand: null,
                model: null,
                transmission: null,
                fuel_type: null,
                seats: null,
                description: null,
            },
            is_saving: false,
            is_loading_brands: false,
            brandOptions: []
        }
    },
    validations: {
        vehicle: {
            license_plate: {required},
            brand: {required},
            model: {required},
            transmission: {required},
            fuel_type: {required},
            seats: {required},
            description: {required},

        }
    },
    methods: {
        close(status) {
            this.$modal.hide('vehicles-create-modal', status);
        },
        save() {
            this.$v.vehicle.$touch();
            if (this.$v.vehicle.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = Object.assign({}, this.$v.vehicle.$model);
            payload.brand_id = this.$v.vehicle.brand.$model.id;
            delete payload.brand;

            payload.status = 'available'

            this.$axios.post(`vehicles`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('vehicles.success_created'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('vehicles.error_create')),
                    type: 'error',
                });
            });
        },

        retrieveBrandOptions() {
            this.is_loading_brands = false;
            this.$axios.get('brands/list')
                .then(({data}) => {
                    this.brandOptions = data.data.map(p => ({id: p.id, name: p.attributes.name}));
                    this.is_loading_brands = false;
                })
                .catch(e => {
                    this.is_loading_brands = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('brands.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },

    mounted() {
        this.retrieveBrandOptions();
    }
}
</script>
