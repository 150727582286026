<template>
    <div class="users-create-modal">
        <ModalContainer :title="$t('vehicles.add_brand')" identifier="vehicles-brand-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.brand.name.$model" identifier="name" :label="$t('vehicles.name')"
                               :placeholder="$t('vehicles.name')" :disabled="is_saving"
                               :has-error="$v.brand.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.brand.name.required">
                            {{$t('validation.x_is_required',{x: $t('vehicles.name')})}}
                        </p>
                    </template>
                </FormInputText>
                <div class="buttons">
                    <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                        {{$t('add')}}
                    </Button>
                    <Button className="--primary --outline --small" :onclick="back" :class="{spinner: is_saving}">
                        {{$t('back')}}
                    </Button>
                </div>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import VehiclesBrandIndexModal from "./VehiclesBrandIndexModal";

export default {
    name: "VehiclesBrandCreateModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            brand: {
                name: null,
            },
            is_saving: false,
            is_loading_original: false,
        }
    },

    validations: {
        brand: {
            name: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('vehicles-brand-create-modal', status);
        },

        back(){
            this.close(false);
            this.$modal.show(
                VehiclesBrandIndexModal, {},
                {
                    name: 'vehicles-brand-index-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                    }
                }
            );
        },

        save() {
            this.$v.brand.$touch();
            if (this.$v.brand.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = Object.assign({}, this.$v.brand.$model);

            this.$axios.post(`brands/`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('brands.success_created'),
                    type: 'success',
                });
                this.is_saving = false;

                this.close(false);
                this.$modal.show(
                    VehiclesBrandIndexModal, {},
                    {
                        name: 'vehicles-brand-index-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                        }
                    }
                );
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('brands.error_create')),
                    type: 'error',
                });
            });
        },
    },
}
</script>

<style lang="scss" scoped>

.buttons {
    @apply flex flex-row justify-between;

    button {
        @apply mx-2 mt-0 px-8 py-3;
    }
}

</style>