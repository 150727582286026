<template>
    <div class="users-update-modal">
        <ModalContainer :title="$t('users.edit_user')" identifier="users-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormGroupTwo>
                    <FormInputText v-model="$v.user.name.$model" identifier="name" :label="$t('users.name')"
                                   :placeholder="$t('users.name')" :disabled="is_saving"
                                   :has-error="$v.user.name.$error">
                        <template v-slot:errors>
                            <p v-if="!$v.user.name.required">
                                {{$t('validation.x_is_required',{x: $t('users.name')})}}
                            </p>
                        </template>
                    </FormInputText>
                    <FormInputText v-model="$v.user.email.$model" identifier="email" :label="$t('users.email')"
                                   :placeholder="$t('users.email')" :disabled="is_saving"
                                   :has-error="$v.user.email.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.user.email.required">
                                {{$t('validation.x_is_required',{x: $t('users.email')})}}
                            </p>
                            <p v-else-if="!$v.user.email.email">
                                {{$t('auth.enter_valid_email')}}
                            </p>
                        </template>
                    </FormInputText>
                </FormGroupTwo>
                <FormGroupTwo>
                    <FormInputPassword v-model="$v.user.password.$model" identifier="password" :label="$t('users.password')"
                                       :placeholder="$t('users.password')" :disabled="is_saving" type="password"
                                       :has-error="$v.user.password.$error" autocomplete="off">
                        <template v-slot:errors>
                            <p v-if="!$v.user.password.required">
                                {{$t('validation.x_is_required',{x: $t('users.password')})}}
                            </p>
                        </template>
                    </FormInputPassword>
                    <FormInputSelect v-model="$v.user.role.$model" identifier="role"
                                     :label="$t('users.role')" :options="roleOptions"
                                     :placeholder="$t('users.role')" :disabled="is_saving"
                                     :has-error="$v.user.role.$error" track-by="id"
                                     :display-custom-label="(row) => `${row.attributes.name}`">
                        <template v-slot:errors>
                            <p v-if="!$v.user.role.required">
                                {{$t('validation.x_is_required',{x: $t('users.role')})}}
                            </p>
                        </template>
                    </FormInputSelect>
                </FormGroupTwo>
                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import _ from 'lodash';
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "UsersUpdateModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    props: {
        user_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            original: null,
            user: {
                name: null,
                email: null,
                password: null,
                role: null,
            },
            is_saving: false,
            roleOptions: [],
            is_loading_roles: false,
            is_loading_original: false,
        }
    },
    validations: {
        user: {
            name: {required},
            email: {required, email},
            password: {},
            role: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('users-update-modal', status);
        },
        save() {
            this.$v.user.$touch();
            if (this.$v.user.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = {};

            payload.name = this.$v.user.name.$model;

            if (this.original.attributes.email !== this.$v.user.email.$model)
                payload.email = this.$v.user.email.$model;

            if (this.$v.user.role.$model)
                payload.roles = [this.$v.user.role.$model.id];

            if (this.$v.user.password.$model) {
                payload.password = this.$v.user.password.$model;
            }

            this.$axios.patch(`users/${this.user_id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('users.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('users.error_update')),
                    type: 'error',
                });
            });
        },
        populate() {
            if (!this.original || !this.user)
                return;

            this.$v.user.name.$model = this.original.attributes.name;
            this.$v.user.email.$model = this.original.attributes.email;
            this.$v.user.role.$model = _.find(this.roleOptions, {id: this.original.relationships.roles.data[0].id});
        },
        async retrieveOriginalUser() {
            this.is_loading_original = false;
            await this.$axios.get(`users/${this.user_id}`)
                .then(({data}) => {
                    this.is_loading_original = false;
                    this.original = data.data;
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        async retrieveRoleOptions() {
            this.is_loading_roles = false;
            await this.$axios.get('roles/list')
                .then(({data}) => {
                    this.roleOptions = data.data;
                    this.is_loading_roles = false;
                })
                .catch(e => {
                    this.is_loading_roles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('roles.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },

    async mounted() {
        await this.retrieveOriginalUser();
        await this.retrieveRoleOptions();
        this.populate();
    }
}
</script>
