<template>
    <div class="mobile-menu-container">
        <router-link v-if="$store.getters.hasPermission('read bookings')" :to="{name: 'bookings-index'}"
                     class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'book-alt']"/>
            </div>
            <p>{{ $t('nav.bookings') }}</p>
        </router-link>
        <router-link v-if="$store.getters.hasPermission('read issues')" :to="{name: 'issues-index'}"
                     class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'clipboard-check']"/>
            </div>
            <p>{{ $t('nav.issues') }}</p>
        </router-link>
        <router-link v-if="$store.getters.hasPermission('read vehicles')" :to="{name: 'vehicles-index'}"
                     class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'car']"/>
            </div>
            <p>{{ $t('nav.vehicles') }}</p>
        </router-link>
        <router-link v-if="$store.getters.hasPermission('read reports')" :to="{name: 'reports-index'}"
                     class="nav-item" @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">
            <div class="icon-container">
                <font-awesome-icon :icon="['far', 'chart-bar']"/>
            </div>
            <p>{{ $t('nav.reports') }}</p>
        </router-link>
        <router-link v-if="$store.getters.hasPermission('read roles')" :to="{name: 'roles-index'}" class="nav-item"
                     @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'key']"/>
            </div>
            <p>{{ $t('nav.roles_permissions') }}</p>
        </router-link>
        <router-link v-if="$store.getters.hasPermission('read users')" :to="{name: 'users-index'}" class="nav-item"
                     @click.native="$store.commit('TOGGLE_MENU_EXPANDED', false)">
            <div class="icon-container">
                <font-awesome-icon :icon="['fal', 'user-friends']"/>
            </div>
            <p>{{ $t('nav.users') }}</p>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "MobileMenu"
}
</script>

<style lang="scss" scoped>
.mobile-menu-container {
    @apply flex-1 grid grid-cols-2 content-start gap-y-12 gap-x-8 h-auto p-8 bg-white;

    @screen sm {
        @apply grid-cols-3;
    }

    @screen md {
        @apply hidden;
    }

    .nav-item {
        @apply flex flex-col items-center;

        .icon-container {
            @apply p-2 rounded-lg border-2 border-grey-light flex flex-col items-center justify-center;
            height: 40px;
            width: 40px;

            svg {
                @apply text-black w-auto;

                height: 18px;
            }
        }

        p {
            @apply text-black text-sm font-bold mt-4 text-center;
        }

        &:hover, &:focus {
            .icon-container {
                @apply border-primary;
            }
        }

        &.router-link-active {
            .icon-container {
                @apply bg-primary border-primary;

                svg {
                    @apply text-white;
                }
            }
        }
    }
}
</style>