<template>
    <div class="users-create-modal">
        <ModalContainer :title="$t('vehicles.vehicles_brands')" identifier="vehicles-brand-index-modal"
                        :closable="true">

            <vue-good-table
                mode="remote"
                styleClass="vgt-table vgt-custom vgt-white"
                :columns="columns"
                :rows="brands"
                :isLoading.sync="is_loading_brands"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: false,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('vehicles.brands')}),
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                :totalRows="totalRecords">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'attributes.brand'">
                        <p style="text-transform: capitalize;">
                            {{ props.row.relationships.brand.data.attributes.name }}</p>
                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <Button
                            v-if="$store.getters.hasAnyPermission(['read brands', 'update brands'])"
                            className="--secondary --outline --mini --big-text"
                            :onclick="()=>toggleEdit(props.row)">
                            <font-awesome-icon :icon="['fal', 'pencil']"/>
                        </Button>

                        <Button
                            v-if="$store.getters.hasPermission('destroy brands')"
                            className="--secondary --outline --mini --big-text"
                            :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>

            <div class="buttons">
                <Button :className="`--primary wider`" :onclick="()=>close(true)">{{ $t('save') }}</Button>
                <Button
                    v-if="$store.getters.hasPermission('store brands')"
                    :className="`--primary --outline wider`"
                    :onclick="addBrand">
                    {{ $t('vehicles.add_brand') }}
                </Button>
            </div>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import VehiclesBrandCreateModal from "./VehiclesBrandCreateModal";
import VehiclesBrandUpdateModal from "./VehiclesBrandUpdateModal";
import VehiclesBrandDeleteModal from "./VehiclesBrandDeleteModal";

export default {
    name: "VehiclesBrandIndexModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        const columns = [
            {
                label: this.$t('vehicles.name'),
                field: 'attributes.name',
                sortable: false,
            },
        ];

        if (this.$store.getters.hasAnyPermission(['update brands', 'destroy brands']))
            columns.push({
                label: this.$t('actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            totalRecords: 0,
            is_saving: false,
            is_loading_brands: false,
            brands: []
        }
    },
    validations: {
        brand: {
            name: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('vehicles-brand-index-modal', status);
        },
        save() {
            this.$v.brand.$touch();
            if (this.$v.brand.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = Object.assign({}, this.$v.brand.$model);

            this.$axios.post(`brands`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('brands.success_created'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('brands.error_create')),
                    type: 'error',
                });
            });
        },

        addBrand() {
            this.close(false);
            this.$modal.show(
                VehiclesBrandCreateModal, {},
                {
                    name: 'vehicles-brand-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveBrands();
                    }
                }
            );
        },

        toggleEdit(brand) {
            this.close(false);
            this.$modal.show(
                VehiclesBrandUpdateModal, {
                    brand_id: brand.id
                },
                {
                    name: 'vehicles-brand-update-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveBrands();
                    }
                }
            );

        },

        toggleDelete(brand) {
            this.close(false);
            this.$modal.show(
                VehiclesBrandDeleteModal, {
                    brand_id: brand.id
                },
                {
                    name: 'vehicles-brand-delete-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveBrands();
                    }
                }
            );

        },

        retrieveBrands() {
            this.is_loading_brands = false;
            this.$axios.get('brands/list')
                .then(({data}) => {
                    this.brands = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_brands = false;
                })
                .catch(e => {
                    this.is_loading_brands = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('brands.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },

    mounted() {
        this.retrieveBrands();
    }
}
</script>

<style lang="scss" scoped>

.buttons {
    @apply flex flex-row justify-between mt-16;

    button {
        @apply mx-2 mt-0 px-8 py-3;
    }
}

.td-after {
    @apply flex flex-row;

    & > * {
        @apply mr-3;

        &:last-child {
            @apply mr-0;
        }
    }
}


</style>