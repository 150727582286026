<template>
    <div class="page-container" v-if="!is_loading">
        <Headbar>
            <template v-slot:left>
                <h1 v-if="original">{{ original.attributes.license_plate }}</h1>
            </template>
            <template v-slot:right>
                <div class="status-select-container">
                    <p>{{ $t('vehicles.status') }}</p>
                    <FormInputSelect v-model="vehicle_status" :placeholder="$t('vehicles.status')" :allow-empty="false"
                                     :options="statusOptions" identifier="name" :disabled="is_saving"
                                     @input="(e) => changedVehicleStatus(e)"
                                     track-by="name" :display-custom-label="(row) => `${row.name}`"/>
                </div>
                <Button className="--primary --small --wider"
                        :class="{spinner: is_saving}" :onclick="save">
                    {{ $t('save') }}
                </Button>
            </template>
        </Headbar>
        <main>
            <FormGroupTwo class="border-b-0.5 border-primary height-fix">
                <div class="section-container half">
                    <Form class="form" :disabled="!$store.getters.hasPermission('update vehicles') || is_saving">
                        <SectionHeader :title="$t('vehicles.vehicle_details')"></SectionHeader>
                        <div class="form-body">
                            <FormGroupTwo>
                                <FormInputText v-model="$v.vehicle.license_plate.$model" identifier="license_plate"
                                               :label="$t('vehicles.license_plate')"
                                               :placeholder="$t('vehicles.license_plate')" :disabled="is_saving"
                                               :has-error="$v.vehicle.license_plate.$error">
                                    <template v-slot:errors>
                                        <p v-if="!$v.vehicle.license_plate.required">
                                            {{ $t('validation.x_is_required', {x: $t('vehicles.license_plate')}) }}
                                        </p>
                                    </template>
                                </FormInputText>
                                <FormInputSelect v-model="$v.vehicle.brand.$model" identifier="brand"
                                                 :label="$t('vehicles.brand')" :options="brandOptions"
                                                 :placeholder="$t('vehicles.brand')" :disabled="is_saving"
                                                 :has-error="$v.vehicle.brand.$error" track-by="id"
                                                 display-label="name">
                                    <template v-slot:errors>
                                        <p v-if="!$v.vehicle.brand.required">
                                            {{ $t('validation.x_are_required', {x: $t('vehicles.brand')}) }}
                                        </p>
                                    </template>
                                </FormInputSelect>
                            </FormGroupTwo>
                            <FormGroupTwo>
                                <FormInputText v-model="$v.vehicle.model.$model" identifier="model"
                                               :label="$t('vehicles.model')"
                                               :placeholder="$t('vehicles.model')" :disabled="is_saving"
                                               :has-error="$v.vehicle.model.$error">
                                    <template v-slot:errors>
                                        <p v-if="!$v.vehicle.model.required">
                                            {{ $t('validation.x_is_required', {x: $t('vehicles.model')}) }}
                                        </p>
                                    </template>
                                </FormInputText>
                                <FormInputText v-model="$v.vehicle.transmission.$model" identifier="transmission"
                                               :label="$t('vehicles.transmission')"
                                               :placeholder="$t('vehicles.transmission')" :disabled="is_saving"
                                               :has-error="$v.vehicle.transmission.$error">
                                    <template v-slot:errors>
                                        <p v-if="!$v.vehicle.transmission.required">
                                            {{ $t('validation.x_is_required', {x: $t('vehicles.transmission')}) }}
                                        </p>
                                    </template>
                                </FormInputText>
                            </FormGroupTwo>
                            <FormGroupTwo>
                                <FormInputText v-model="$v.vehicle.fuel_type.$model" identifier="fuel_type"
                                               :label="$t('vehicles.fuel_type')"
                                               :placeholder="$t('vehicles.fuel_type')" :disabled="is_saving"
                                               :has-error="$v.vehicle.fuel_type.$error">
                                    <template v-slot:errors>
                                        <p v-if="!$v.vehicle.fuel_type.required">
                                            {{ $t('validation.x_is_required', {x: $t('vehicles.fuel_type')}) }}
                                        </p>
                                    </template>
                                </FormInputText>
                                <FormInputText v-model="$v.vehicle.seats.$model" identifier="seats"
                                               :label="$t('vehicles.seats')"
                                               :placeholder="$t('vehicles.seats')" :disabled="is_saving"
                                               :has-error="$v.vehicle.seats.$error">
                                    <template v-slot:errors>
                                        <p v-if="!$v.vehicle.seats.required">
                                            {{ $t('validation.x_is_required', {x: $t('vehicles.seats')}) }}
                                        </p>
                                    </template>
                                </FormInputText>
                            </FormGroupTwo>
                            <FormInputText v-model="$v.vehicle.description.$model" identifier="description" :label="$t('vehicles.description')"
                                           :placeholder="$t('vehicles.description')" :disabled="is_saving"
                                           :has-error="$v.vehicle.description.$error" :use-textarea="true" :large-textarea="true">
                                <template v-slot:errors>
                                    <p v-if="!$v.vehicle.description.required">
                                        {{$t('validation.x_is_required',{x: $t('vehicles.description')})}}
                                    </p>
                                </template>
                            </FormInputText>
                        </div>
                    </Form>
                </div>
                <div class="section-container half h-auto" style="margin-bottom: 2rem !important">
                    <Form class="form h-full" :disabled="is_saving">
                        <SectionHeader :title="$t('vehicles.vehicle_images')"></SectionHeader>
                        <div class="form-body">
                            <div v-if="vehicleImages.length" class="manage-upload">
                                <div class="images-container">
                                    <div v-for="(image, index) in vehicleImages" class="image-wrapper">
                                        <div class="image-container">
                                            <button type="button" class="btn-delete" @click="removeAttachment(index)">
                                                <font-awesome-icon :icon="['far','times']"/>
                                            </button>
                                            <img :src="image.preview"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="upload-row">
                                    <label for="property-create-file-upload">
                                        <font-awesome-icon :icon="['fal','upload']"/>
                                        <p>{{$t('upload')}}</p>
                                    </label>
                                </div>
                            </div>
                            <div v-else class="upload">
                                <label for="property-create-file-upload">
                                    <font-awesome-icon :icon="['fal','upload']"/>
                                    <p>{{$t('upload')}}</p>
                                </label>
                            </div>

                            <input type="file" name="file" id="property-create-file-upload" accept="image/*" multiple
                                   @change="handleFileChange" :disabled="is_saving"/>
                        </div>
                    </Form>
                </div>
            </FormGroupTwo>

            <FormGroupTwo class="border-b-2 border-primary height-fix">
                <div class="section-container half h-auto">
                    <h2>{{ $t('vehicles.issue_history') }}</h2>
                    <vue-good-table
                        styleClass="vgt-table vgt-custom vgt-responsive-fix"
                        :columns="issueHistoryColumns"
                        :rows="issueHistory"
                        :isLoading.sync="is_loading"
                        :search-options="{
                    enabled: false,
                }"
                        :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('vehicles.issues')}),
                }"
                        :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                        :totalRows="issueHistory_records"
                        @on-page-change="onPageChangeIssueHistory">
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field === 'attributes.updated_at'">
                                <p style="text-transform: capitalize;">{{ $moment(props.row.attributes.updated_at).format('DD/MM/YYYY - HH:mm') }}</p>
                            </div>
                            <div v-else-if="props.column.field === 'attributes.reason'" class="text-overflow-fix">
                                <p style="text-transform: capitalize;">{{ props.row.attributes.comment }}</p>
                            </div>
                            <span v-else style="text-transform: capitalize;">{{ props.formattedRow[props.column.field] }}</span>
                        </template>
                    </vue-good-table>
                </div>
                <div class="section-container half h-auto">
                    <h2>{{ $t('vehicles.audits') }}</h2>
                    <vue-good-table
                        styleClass="vgt-table vgt-custom vgt-responsive-fix"
                        :columns="auditsColumns"
                        :rows="audits"
                        :isLoading.sync="is_loading"
                        :search-options="{
                    enabled: false,
                }"
                        :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('vehicles.audits')}),
                }"
                        :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                        :totalRows="audits_records"
                        @on-page-change="onPageChangeAudits">
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field === 'attributes.updated_at'">
                                <p style="text-transform: capitalize;">
                                    {{ $moment(props.row.attributes.updated_at).format('DD/MM/YYYY - HH:mm') }}</p>
                            </div>
                            <div v-else-if="props.column.field === 'attributes.status'">
                                <p style="text-transform: capitalize;">{{ props.row.attributes.status }}</p>
                            </div>
                            <div v-else-if="props.column.field === 'values'" class="td-values">
                                <div v-if="Object.keys(props.row.attributes.old_values).length" class="row">
                                    <p class="name">{{$t('bookings.old_values')}}</p>
                                    <div class="values">
                                        <p v-for="key in Object.keys(props.row.attributes.old_values)"><strong>{{$t(`vehicles.${key}`)}}: </strong>{{props.row.attributes.old_values[key]}}</p>
                                    </div>
                                </div>
                                <div v-if="Object.keys(props.row.attributes.new_values).length" class="row">
                                    <p class="name">{{$t('bookings.new_values')}}</p>
                                    <div class="values">
                                        <p v-for="key in Object.keys(props.row.attributes.new_values)"><strong>{{$t(`vehicles.${key}`)}}: </strong>{{props.row.attributes.new_values[key]}}</p>
                                    </div>
                                </div>
                            </div>
                            <span v-else style="text-transform: capitalize;">{{ props.formattedRow[props.column.field] }}</span>
                        </template>
                    </vue-good-table>
                </div>
            </FormGroupTwo>

        </main>
    </div>
</template>

<script>
import _ from 'lodash';
import Headbar from "../../components/headbar/Headbar";
import Form from "../../components/form/Form";
import SectionHeader from "../../components/SectionHeader";
import Button from "../../components/Button";
import FormInputText from "../../components/form/FormInputText";
import FormInputSelect from "../../components/form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import FormGroupTwo from "../../components/form/FormGroupTwo";
import FormInputPassword from "../../components/form/FormInputPassword";
import ConfirmModal from "@/components/modal/ConfirmModal";
import FormInputDateTime from "../../components/form/FormInputDateTime";
import VehicleStatusUnavailableModal from "../../components/vehicles/VehicleStatusUnavailableModal";


export default {
    name: "vehicles-update-page",
    components: {
        FormInputDateTime,
        FormGroupTwo, FormInputText, FormInputPassword, FormInputSelect, Button, SectionHeader, Form, Headbar
    },
    data: function () {
        let issueHistoryColumns = [
            {
                label: this.$t('vehicles.id'),
                field: 'id',
                sortable: false,
            },
            {
                label: this.$t('vehicles.reason'),
                field: 'attributes.reason',
                sortable: false,
            },
            {
                label: this.$t('vehicles.date_and_time'),
                field: 'attributes.updated_at',
                sortable: false,
            },
        ];

        let auditsColumns = [
            {
                label: this.$t('vehicles.user'),
                field: 'relationships.user.data.attributes.name',
                sortable: false,
            },
            {
                label: this.$t('vehicles.event'),
                field: 'attributes.event',
                sortable: false,
            },
            {
                label: this.$t('vehicles.detail'),
                field: 'values',
                sortable: false,
            },
            {
                label: this.$t('vehicles.date_and_time'),
                field: 'attributes.updated_at',
                sortable: false,
            },
        ];

        return {
            original: null,
            issueHistoryColumns: issueHistoryColumns,
            auditsColumns: auditsColumns,
            brandOptions: [],
            vehicleImages: [],
            attachmentsToDelete: [],

            issueHistory: [],
            audits: [],
            issueHistory_records: 0,
            audits_records: 0,
            auditsServerParams: {sorting: []},
            issueHistoryServerParams: {sorting: []},
            is_loading_audits: false,
            is_loading_issue_history: false,
            is_loading_vehicle_images: false,

            vehicle: {
                license_plate: null,
                brand: null,
                model: null,
                transmission: null,
                fuel_type: null,
                seats: null,
                description: null,
            },
            vehicle_status: null,
            statusOptions: [{id: 1, name: 'Available', value: 'available'}, {id: 2, name: 'Unavailable', value: 'unavailable'}],
            is_saving: false,
            is_loading: true,
            files_uploaded: false,
        }
    },
    validations: {
        vehicle: {
            license_plate: {
                required
            },
            brand: {
                required
            },
            model: {
                required
            },
            transmission: {
                required
            },
            fuel_type: {
                required
            },
            seats: {
                required
            },
            description: {
                required
            },
        }
    },
    methods: {
        async populate() {
            if (!this.original) return;

            this.$v.vehicle.license_plate.$model = this.original.attributes.license_plate;
            this.$v.vehicle.brand.$model = _.find(this.brandOptions, {id: this.original.relationships.brand.data.id});
            this.$v.vehicle.model.$model = this.original.attributes.model;
            this.$v.vehicle.transmission.$model = this.original.attributes.transmission;
            this.$v.vehicle.fuel_type.$model = this.original.attributes.fuel_type;
            this.$v.vehicle.seats.$model = this.original.attributes.seats;
            this.$v.vehicle.description.$model = this.original.attributes.description;
            this.vehicle_status = _.find(this.statusOptions, {value: this.original.attributes.status});
            this.is_loading = false;
        },

        async retrieveOriginalVehicle() {
            await this.$axios.get(`vehicles/${this.$route.params.id}`)
                .then(({data}) => {
                    this.original = data.data
                })
                .catch(e => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('vehicles.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        async retrieveBrandOptions() {
            await this.$axios.get('brands/list')
                .then(({data}) => {
                    this.brandOptions = data.data.map(p => ({id: p.id, name: p.attributes.name}));
                })
                .catch(e => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('brands.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        updateParamsIssueHistory(newProps) {
            this.issueHistoryServerParams = Object.assign({}, this.issueHistoryServerParams, newProps);
        },

        onPageChangeIssueHistory(params) {
            this.updateParamsIssueHistory({page: params.currentPage});
            this.retrieveIssueHistory();
        },

        updateParamsAudits(newProps) {
            this.auditsServerParams = Object.assign({}, this.auditsServerParams, newProps);
        },

        onPageChangeAudits(params) {
            this.updateParamsAudits({page: params.currentPage});
            this.retrieveAudits();
        },

        async retrieveAudits() {
            this.is_loading_audits = true;

            const encodedSorting = this.auditsServerParams.sorting.map(f => btoa(JSON.stringify(f)));

            await this.$axios.get(`vehicles/${this.$route.params.id}/audits`, {params: {...this.auditsServerParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.audits = data.data;
                    this.audits_records = data.meta.total;
                    this.is_loading_audits = false;
                })
                .catch(e => {
                    this.is_loading_audits = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve_audits')),
                        type: 'error',
                    });
                });
        },

        async retrieveIssueHistory() {
            this.is_loading_issue_history = true;

            const encodedSorting = this.issueHistoryServerParams.sorting.map(f => btoa(JSON.stringify(f)));

            await this.$axios.get(`vehicles/${this.$route.params.id}/issues`, {params: {...this.issueHistoryServerParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.issueHistory = data.data;
                    this.issueHistory_records = data.meta.total;
                    this.is_loading_issue_history = false;
                })
                .catch(e => {
                    this.is_loading_issue_history = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('vehicles.error_retrieve_issue_history')),
                        type: 'error',
                    });
                });
        },

        async retrieveVehicleImages() {
            this.is_loading_vehicle_images = true;
            await this.$axios.get(`vehicles/${this.$route.params.id}/documents`)
                .then(({data}) => {
                    if (data.data.length) {
                        this.vehicleImages = data.data.map(d => ({
                            id: d.id,
                            name: d.attributes.name,
                            preview: d.attributes.image_path,
                            file: null,
                        }));
                    }

                    this.is_loading_vehicle_images = false;
                })
                .catch(e => {
                    this.is_loading_vehicle_images = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('vehicles.error_documents_retrieve')),
                        type: 'error',
                    });
                });
        },

        changedVehicleStatus(e) {
            if (!e) return;
            if (e.name === 'Unavailable') {
                this.$modal.show(
                    VehicleStatusUnavailableModal, {
                        vehicle_id: Number(this.$route.params.id)

                    },
                    {
                        name: 'vehicle-status-unavailable-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                            if (e.params === true)
                                this.retrieveVehicles();
                            else
                                this.vehicle_status = {id: 1, name: 'Available', value: 'available'}
                        }
                    }
                );
            }
        },

        async changeStatus(status) {
            let issuesToFix = null

            await this.$axios.get(`vehicles/${this.$route.params.id}/issues`)
                .then(({data}) => {
                    issuesToFix = data.data
                    this.is_loading_issues = false
                })
                .catch(e => {
                    this.is_loading_issues = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('vehicles.error_retrieve_issues')),
                        type: 'error',
                    });
                });

            let temp = []
            if(issuesToFix.length > 0){
                issuesToFix.forEach(issue => {
                    temp.push(issue.id)
                })
            }

            await this.$axios.post(`vehicles/${this.$route.params.id}/update-status`, {status: status, issues_to_fix: temp}).then(({data}) => {
                this.$notify({
                    text: this.$t('issues.success_updated_vehicle'),
                    type: 'success',
                });
                this.is_saving = false;

            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('issues.error_updated_vehicle')),
                    type: 'error',
                });
            });
        },

        removeAttachment(index) {
            if (index < 0 || index >= this.vehicleImages.length)
                return;

            if (this.vehicleImages[index].id)
                this.attachmentsToDelete.push(this.vehicleImages[index].id);

            this.vehicleImages.splice(index, 1);
        },

        handleFileChange(e) {
            const files = Array.from(e.target.files);

            files.forEach(async file => {
                this.vehicleImages.push({
                    file: file,
                    preview: await this.getBase64FromFile(file),
                    name: file.name,
                });
            });
        },

        getBase64FromFile(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },

        uploadFile(vehicle_id, attachment, name) {
            let formData = new FormData();
            formData.append('name', name);
            formData.append('document', attachment);

            return this.$axios.post(`vehicles/${vehicle_id}/documents`, formData);
        },

        deleteFile(vehicle_id, id) {
            return this.$axios.delete(`vehicles/${vehicle_id}/documents/${id}`);
        },

        async save() {
            this.$v.vehicle.$touch();
            if (this.$v.vehicle.$anyError || this.is_saving)
                return;

            let payload = Object.assign({}, this.$v.vehicle.$model);
            payload.status = this.vehicle_status.value

            if (payload.license_plate === this.original.attributes.license_plate)
                delete payload.license_plate

            await this.changeStatus(payload.status);

            this.is_saving = true;

            this.$axios.patch(`vehicles/${this.$route.params.id}`, payload).then(async ({data}) => {
                const vehicle_id = this.$route.params.id;

                if (this.vehicleImages.filter(a => !a.id).length || this.attachmentsToDelete.length) {
                    const filePromises = [];

                    this.vehicleImages.filter(a => !a.id).forEach(attachment => {
                        filePromises.push(this.uploadFile(vehicle_id, attachment.file, attachment.name));
                    });

                    this.attachmentsToDelete.forEach(attachment => {
                        filePromises.push(this.deleteFile(vehicle_id, attachment));
                    });

                    await Promise.all(filePromises).then(() => {
                        this.$notify({
                            text: this.$t('vehicles.success_updated'),
                            type: 'success',
                        });

                        this.is_saving = false;
                    }).catch(e => {
                        this.$notify({
                            title: this.$t('error'),
                            text: this.$t('error_save_files'),
                            type: 'error',
                        });
                    });

                    await this.retrieveOriginalVehicle();
                    await this.populate();
                } else {
                    this.$notify({
                        text: this.$t('vehicles.success_updated'),
                        type: 'success',
                    });

                    this.is_saving = false;

                    await this.retrieveOriginalVehicle();
                    await this.populate();
                }

            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('vehicles.error_update')),
                    type: 'error',
                });
            });
        },
    },

    async mounted() {
        this.is_loading = true;
        await this.retrieveBrandOptions();
        await this.retrieveOriginalVehicle();
        await this.populate();
        await this.retrieveVehicleImages();
        await this.retrieveAudits();
        await this.retrieveIssueHistory();
    },

    head() {
        return {
            title: {
                inner: this.$t('vehicles.vehicle')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.vgt-custom {
    @apply w-max rounded-t;
    width: max-content !important;
}

.page-container {

    .height-fix{
        align-items: stretch !important
    }

    .text-overflow-fix{
        width: 12rem;

        @screen md{
            width: 32rem;
        }

        p{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .section-container {
        @apply border-b-2 border-primary py-7 px-8 w-full;

        &.half {
            @apply w-1/2 border-b-0;
        }


        h2 {
            @apply text-black font-bold text-2xl pb-4;
        }

    }

    .status-select-container {
        @apply flex flex-row items-center;

        p {
            @apply mr-4 text-xs;
        }

        .input-group {
            @apply mb-0 w-40 mr-4;
        }
    }

    main {
        @apply flex flex-col flex-wrap p-0;

        .section-container {

            &.half {
                @apply w-full;

                @screen xl {
                    @apply w-1/2;
                }
            }

            .file-upload-container{
                @apply w-full h-full flex cursor-pointer;

                .upload-button{
                    @apply flex flex-col m-auto;

                    & > svg{
                        @apply text-5xl mb-4 text-primary;
                    }

                    & > p{
                        @apply text-xl font-bold;
                    }
                }


            }
        }

        .section-container:last-child {
            @apply border-b-0;
        }


        .form {
            @apply mb-8 w-full;

            .form-body {
                @apply px-8 py-7;

                .manage-upload {
                    .images-container {
                        @apply flex flex-row flex-wrap -m-3;

                        .image-wrapper {
                            @apply p-3 w-1/4;

                            .image-container {
                                @apply border-2 border-grey-light rounded p-2 relative;

                                img {
                                    @apply rounded;
                                }

                                .btn-delete {
                                    @apply border-2 border-grey-light rounded flex flex-row items-center justify-center h-5 w-5 absolute right-0 top-0 bg-white;

                                    margin-top: -0.625rem;
                                    margin-right: -0.625rem;

                                    svg {
                                        @apply text-black;

                                        font-size: .7rem;
                                    }

                                    &:active, &:focus {
                                        @apply outline-none;
                                    }
                                }
                            }
                        }
                    }

                    .upload-row {
                        @apply flex flex-row justify-end mt-5;

                        label {
                            @apply py-4 px-7 font-bold rounded border-2 border-primary flex flex-row items-center ml-auto;

                            svg {
                                @apply text-black text-xl;
                            }

                            p {
                                @apply text-black font-bold text-sm ml-3;
                            }
                        }
                    }
                }

                .upload {
                    label {
                        @apply flex flex-col items-center;

                        svg {
                            @apply text-primary mb-4;

                            font-size: 4.5rem;
                        }

                        p {
                            @apply text-black font-bold text-xl;
                        }
                    }
                }

                input[type="file"] {
                    @apply hidden;
                }

                &.next-of-kin {
                    .next-of-kin-row-group {
                        .input-group {
                            @apply w-auto flex-1;
                        }

                        .button-remove {
                            @apply flex-none self-start mt-9;
                        }
                    }
                }
            }

            .row-add-container {
                @apply px-8 pb-8 mt-auto;
            }
        }

        .table {
            @apply w-full;

            @screen lg {
                @apply ml-4;
                width: calc(100% - 1rem);
            }

            &:nth-child(odd) {
                @screen lg {
                    @apply mr-4;
                }
            }

            &:nth-child(even) {
                @screen lg {
                    @apply ml-4;
                }
            }

            .td-after {
                @apply flex;

                svg {
                    @apply text-negative text-lg cursor-pointer ml-auto;

                    &:hover {
                        @apply text-primary-over
                    }
                }
            }
        }
    }
}
</style>