<template>
    <div class="issues-update-modal">
        <ModalContainer :title="$t('issues.update_issue')" identifier="issues-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputSelect v-model="$v.issue.license_plate.$model" identifier="license_plate"
                                 :label="$t('issues.license_plate')" :options="vehicleOptions"
                                 :placeholder="$t('issues.license_plate')"
                                 :has-error="$v.issue.license_plate.$error" track-by="id" :disabled="is_saving"
                                 :display-custom-label="(row) => `${row.attributes.license_plate} - ${row.relationships.brand.data.attributes.name} - ${row.attributes.model}`">
                    <template v-slot:errors>
                        <p v-if="!$v.issue.drop_off_location.required">
                            {{ $t('validation.x_are_required', {x: $t('issues.drop_off_location')}) }}
                        </p>
                    </template>
                </FormInputSelect>

                <FormInputText v-model="$v.issue.issue_description.$model" identifier="issue_description" :label="$t('issues.issue')"
                               :placeholder="$t('issues.issue')" :disabled="is_saving"
                               :has-error="$v.issue.issue_description.$error" :use-textarea="true" :large-textarea="true">
                    <template v-slot:errors>
                        <p v-if="!$v.issue.issue_description.required">
                            {{$t('validation.x_is_required',{x: $t('issues.issue')})}}
                        </p>
                    </template>
                </FormInputText>


                <div class="buttons-container">
                    <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                        {{$t('save')}}
                    </Button>

                    <div class="status-select-container">
                        <p>{{ $t('vehicles.status') }}</p>
                        <FormInputSelect v-model="$v.issue.vehicle_status.$model" :placeholder="$t('vehicles.status')"  :disabled="is_saving" :options="statusOptions" :allow-empty="false"
                                         identifier="vehicle_status" track-by="name" :display-custom-label="(row) => `${row.name}`"/>
                    </div>
                </div>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import FormInputDateTime from "../form/FormInputDateTime";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import _ from "lodash";
export default {
    name: "IssuesUpdateModal",
    components: {FormInputDateTime, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            issue: {
                license_plate: null,
                issue_description: null,
                vehicle_status: null,
            },
            is_saving: false,
            is_loading: false,
            original: null,
            is_loading_vehicles: false,
            vehicleOptions: [],
            statusOptions: [{name: 'Pending', value: 'pending'}, {name: 'Resolved', value: 'resolved'}],
        }
    },
    validations: {
        issue: {
            license_plate: {required},
            issue_description: {required},
            vehicle_status: {required},

        }
    },
    props: {
        issue_id: {
            type: Number,
            required: true,
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('issues-update-modal', status);
        },

        populate(){
            if (!this.original) return;

            this.$v.issue.license_plate.$model =  _.find(this.vehicleOptions, {id: this.original.relationships.vehicle.data.id});
            this.$v.issue.issue_description.$model = this.original.attributes.comment;
            this.$v.issue.vehicle_status.$model = {name: this.original.attributes.status.charAt(0).toUpperCase() + this.original.attributes.status.slice(1), value: this.original.attributes.status}
        },

        save() {
            this.$v.issue.$touch();
            if (this.$v.issue.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            let payload = {
                vehicle_id: this.$v.issue.license_plate.$model.id,
                comment: this.$v.issue.issue_description.$model,
                status: this.$v.issue.vehicle_status.$model.value,
            }

            this.$axios.patch(`issues/${this.issue_id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('issues.success_updated'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('issues.error_update')),
                    type: 'error',
                });
            });
        },

        async retrieveOriginalIssue() {
            this.is_loading = true

            await this.$axios.get(`issues/${this.issue_id}`)
                .then(({data}) => {
                    this.original = data.data
                    this.is_loading = false
                })
                .catch(e => {
                    this.is_loading = false

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('issues.error_retrieve')),
                        type: 'error',
                    });
                });
        },

        async retrieveVehiclesOptions() {
            this.is_loading_vehicles = false;
            await this.$axios.get('vehicles/list')
                .then(({data}) => {
                    this.vehicleOptions = data.data;
                    this.is_loading_vehicles = false;
                })
                .catch(e => {
                    this.is_loading_vehicles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('vehicles.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },

    async mounted() {
        await this.retrieveOriginalIssue();
        await this.retrieveVehiclesOptions();
        this.populate();
    }
}
</script>

<style lang="scss" scoped>
.buttons-container{
    @apply flex flex-row justify-between;

    .status-select-container {
        @apply flex flex-row items-center;

        p {
            @apply mr-4 text-xs;
        }

        .input-group {
            @apply mb-0 w-40 mr-4;
        }
    }

}

</style>