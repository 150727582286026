<template>
    <div class="issues-create-modal">
        <ModalContainer :title="$t('issues.report_an_issue')" identifier="issues-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputSelect v-model="$v.issue.license_plate.$model" identifier="license_plate"
                                 :label="$t('issues.license_plate')" :options="vehicleOptions"
                                 :placeholder="$t('issues.license_plate')" :disabled="is_saving"
                                 :has-error="$v.issue.license_plate.$error" track-by="id"
                                 :display-custom-label="(row) => `${row.attributes.license_plate} - ${row.relationships.brand.data.attributes.name} - ${row.attributes.model}`">
                    <template v-slot:errors>
                        <p v-if="!$v.issue.drop_off_location.required">
                            {{ $t('validation.x_are_required', {x: $t('issues.drop_off_location')}) }}
                        </p>
                    </template>
                </FormInputSelect>

                <FormInputText v-model="$v.issue.issue_description.$model" identifier="issue_description" :label="$t('issues.issue')"
                               :placeholder="$t('issues.issue')" :disabled="is_saving"
                               :has-error="$v.issue.issue_description.$error" :use-textarea="true" :large-textarea="true">
                    <template v-slot:errors>
                        <p v-if="!$v.issue.issue_description.required">
                            {{$t('validation.x_is_required',{x: $t('issues.issue')})}}
                        </p>
                    </template>
                </FormInputText>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('submit')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import FormInputDateTime from "../form/FormInputDateTime";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
export default {
    name: "IssuesCreateModal",
    components: {FormInputDateTime, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            issue: {
                license_plate: null,
                issue_description: null,
            },
            is_saving: false,
            is_loading_vehicles: false,
            vehicleOptions: [],
        }
    },
    validations: {
        issue: {
            license_plate: {required},
            issue_description: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('issues-create-modal', status);
        },
        save() {
            this.$v.issue.$touch();
            if (this.$v.issue.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            let payload = {
                vehicle_id: this.$v.issue.license_plate.$model.id,
                comment: this.$v.issue.issue_description.$model,
                status: 'pending',
                user_id: this.$store.getters.user.id,
            }

            this.$axios.post(`issues`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('issues.success_created'),
                    type: 'success',
                });

                this.is_saving = false;
                this.close(true);
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('issues.error_create')),
                    type: 'error',
                });
            });
        },
        retrieveVehiclesOptions() {
            this.is_loading_vehicles = false;
            this.$axios.get('vehicles/list')
                .then(({data}) => {
                    this.vehicleOptions = data.data;
                    this.is_loading_vehicles = false;
                })
                .catch(e => {
                    this.is_loading_vehicles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('vehicles.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },

    mounted() {
        this.retrieveVehiclesOptions();
    }
}
</script>
