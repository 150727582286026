<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button className="--primary --small" v-if="$store.getters.hasPermission('store bookings')"
                        :onclick="toggleCreate">
                    {{ $t('bookings.add_booking') }}
                </Button>
            </template>
            <template v-slot:right>
                <FormInputSelect v-model="selectedVehicle" identifier="vehicle" :options="vehicles" class="select-margin-fix filter-desktop"
                                 :placeholder="$t('bookings.license_plate')" :disabled="is_loading_vehicles" @updated="onFilterUpdated"
                                 track-by="id"  :display-custom-label="(row) => `${row.attributes.license_plate} - ${row.relationships.brand.data.attributes.name} - ${row.attributes.model}`"/>

                <FormInputSelect v-model="selectedUser" identifier="user" :options="users" class="select-margin-fix filter-desktop"
                                 :placeholder="$t('bookings.booking_by')" :disabled="is_loading_users" @updated="onFilterUpdated"
                                 track-by="id" :display-custom-label="(row) => `${row.attributes.column}`"/>

                <Search class="search-desktop" :placeholder="$t('bookings.search_bookings')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>

            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('bookings.search_bookings')" @search="search"/>
                    <FormInputSelect v-model="selectedVehicle" identifier="vehicle" :options="vehicles" class="select-margin-fix mobile-margin-fix"
                                     :placeholder="$t('bookings.license_plate')" :disabled="is_loading_vehicles" @updated="onFilterUpdated"
                                     track-by="id"  :display-custom-label="(row) => `${row.attributes.license_plate} - ${row.relationships.brand.data.attributes.name} - ${row.attributes.model}`" v-if="headbarExpanded === 'search'"/>

                    <FormInputSelect v-model="selectedUser" identifier="user" :options="users" class="select-margin-fix mobile-margin-fix"
                                     :placeholder="$t('bookings.booking_by')" :disabled="is_loading_users" @updated="onFilterUpdated"
                                     track-by="id" :display-custom-label="(row) => `${row.attributes.column}`" v-if="headbarExpanded === 'search'"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                mode="remote"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="bookings"
                :isLoading.sync="is_loading_bookings"
                :search-options="{enabled: false,}"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('bookings.bookings')}),}"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,}"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">

                    <div v-if="props.column.field === 'id'">
                        <p style="text-transform: capitalize;">{{ props.row.id }}</p>
                    </div>

                    <div v-else-if="props.column.field === 'attributes.from'">
                        <p style="text-transform: capitalize;">{{ $moment(props.row.attributes.from).format('DD/MM/YYYY - HH:mm') }}</p>
                    </div>

                    <div v-else-if="props.column.field === 'attributes.to'">
                        <p style="text-transform: capitalize;">{{ $moment(props.row.attributes.to).format('DD/MM/YYYY - HH:mm') }}</p>
                    </div>

                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <Button className="--secondary --outline --mini --big-text"
                                v-if="props.row.attributes.status === 'pending'"
                                :onclick="()=>toggleStart(props.row)">
                            <font-awesome-icon :icon="['fal', 'play']"/>
                        </Button>
                        <Button className="--secondary --outline --mini --big-text"
                                v-if="props.row.attributes.status === 'in_progress'"
                                :onclick="()=>toggleStop(props.row)">
                            <font-awesome-icon :icon="['fal', 'stop']"/>
                        </Button>
                        <router-link :to="{name: 'bookings-update', params: {id: props.row.id}}">
                            <Button v-if="$store.getters.hasAnyPermission(['read bookings', 'update bookings'])"
                                className="--secondary --outline --mini --big-text">
                                <font-awesome-icon :icon="['fal', 'pencil']"/>
                            </Button>
                        </router-link>
                        <Button v-if="$store.getters.hasPermission('destroy bookings')"
                            className="--secondary --outline --mini --big-text"
                            :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import FormInputSelect from "../../components/form/FormInputSelect";
import BookingsCreateModal from "../../components/bookings/BookingsCreateModal";
import StartBookingModal from "@/components/modal/StartBookingModal";
import StopBookingModal from "@/components/modal/StopBookingModal";

export default {
    name: "bookings-index-page",
    components: {ConfirmModal, Select, Button, Search, Headbar, FormInputSelect},
    data: function () {
        const columns = [
            {
                label: this.$t('bookings.id'),
                field: 'id',
                sortable: false,
                width: '10%',
            },
            {
                label: this.$t('bookings.date_time_from'),
                field: 'attributes.from',
                sortable: false,
                width: '12.5%',

            },
            {
                label: this.$t('bookings.date_time_to'),
                field: 'attributes.to',
                sortable: false,
                width: '12.5%',
            },
            {
                label: this.$t('bookings.obo'),
                field: 'attributes.obo',
                sortable: false,
                width: '12.5%',

            },
            {
                label: this.$t('bookings.license_plate'),
                field: 'relationships.vehicle.data.attributes.license_plate',
                sortable: false,
                width: '12.5%',

            },
            {
                label: this.$t('bookings.model'),
                field: 'relationships.vehicle.data.attributes.model',
                sortable: false,
                width: '12.5%',
            },
            {
                label: this.$t('bookings.booking_by'),
                field: 'relationships.user.data.attributes.name',
                sortable: false,
                width: '12.5%',
            },
            {
                label: this.$t('bookings.kilometers'),
                field: this.kilometersFn,
                sortable: false,
                width: '12.5%',
            },
        ];

        if (this.$store.getters.hasAnyPermission(['update bookings', 'destroy bookings']))
            columns.push({
                label: this.$t('actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false,
                width: '1%',
            });

        return {
            columns: columns,
            bookings: [],
            vehicles: [],
            users: [],
            is_loading_bookings: false,
            is_loading_vehicles: false,
            is_loading_users: false,
            selectedVehicle: null,
            selectedUser: null,
            totalRecords: null,
            serverParams: {sorting: []},
            searchTerm: null,
            is_deleting: false,
            headbarExpanded: false
        }
    },
    methods: {
        kilometersFn(row) {
            if(row.attributes.trip_finish_km && row.attributes.trip_start_km)
                return (parseInt(row.attributes.trip_finish_km) - parseInt(row.attributes.trip_start_km)).toLocaleString('en');
            else
                return 'N/A';
        },
        toggleStart(booking) {
            this.$modal.show(
                StartBookingModal, {
                    title: `${this.$t('bookings.start_booking')} - ${booking.attributes.reference}`,
                    message: this.$t('bookings.please_enter_kilometers'),
                    confirmText: this.$t('start'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline',
                    booking: booking
                },
                {
                    name: 'start-booking-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.retrieveBookings();
                        }
                    }
                }
            );
        },
        toggleStop(booking) {
            this.$modal.show(
                StopBookingModal, {
                    title: `${this.$t('bookings.stop_booking')} - ${booking.attributes.reference}`,
                    message: this.$t('bookings.please_enter_kilometers'),
                    confirmText: this.$t('stop'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline',
                    booking: booking
                },
                {
                    name: 'stop-booking-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.retrieveBookings();
                        }
                    }
                }
            );
        },
        toggleDelete(booking) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('bookings.delete_booking'),
                    message: this.$t('bookings.prompt_delete'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`bookings/${booking.id}`)
                                .then(({data}) => {
                                    this.is_deleting = false;
                                    this.retrieveBookings();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('bookings.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        toggleCreate() {
            this.$modal.show(
                BookingsCreateModal, {},
                {
                    name: 'bookings-create-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.retrieveBookings();
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveBookings();
        },
        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.retrieveBookings();
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.email')
                    sort_by = 'email';
                else if (p.field === 'role')
                    sort_by = 'role';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.retrieveBookings();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm});
            else this.removeParam('term');

            this.retrieveBookings();
        },

        retrieveUsers() {
            this.is_loading_users = true;

            this.$axios.get('users/list')
                .then(({data}) => {
                    this.users = data.data;
                    this.is_loading_users = false;
                })
                .catch(e => {
                    this.is_loading_users = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        retrieveVehicles() {
            this.is_loading_vehicles = true;

            this.$axios.get('vehicles/list')
                .then(({data}) => {
                    this.vehicles = data.data;
                    this.is_loading_vehicles = false;
                })
                .catch(e => {
                    this.is_loading_vehicles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('vehicles.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        onFilterUpdated() {
            const filters = [];

            if (this.selectedUser) {
                filters.push({
                    filter_by: 'user_id',
                    filter_value: this.selectedUser.id
                })
            }

            if (this.selectedVehicle) {
                filters.push({
                    filter_by: 'vehicle_id',
                    filter_value: this.selectedVehicle.id
                })
            }

            if (filters.length)
                this.updateParams({filters: filters})
            else
                this.removeParam('filters');

            this.retrieveBookings();
        },
        retrieveBookings() {
            this.is_loading_bookings = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            this.$axios.get('bookings', {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.bookings = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_bookings = false;
                })
                .catch(e => {
                    this.is_loading_bookings = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    mounted() {
        this.retrieveBookings();
        this.retrieveUsers();
        this.retrieveVehicles();
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.bookings')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .select-margin-fix{
        margin-bottom: 0 !important;
        width: 150px !important;
        height: 50px !important;
        min-height: 50px !important;

        &.mobile-margin-fix{
            @apply mx-auto;
            width: 241px !important;
            padding-right: 4px;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mx-auto;

        @screen md{
            @apply ml-2;
        }

    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row ml-auto;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
