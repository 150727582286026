<template>
    <div class="users-create-modal">
        <ModalContainer :title="$t('vehicles.edit_brand')" identifier="vehicles-brand-update-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.brand.name.$model" identifier="name" :label="$t('vehicles.name')"
                               :placeholder="$t('vehicles.name')" :disabled="is_saving"
                               :has-error="$v.brand.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.brand.name.required">
                            {{$t('validation.x_is_required',{x: $t('vehicles.name')})}}
                        </p>
                    </template>
                </FormInputText>
                <div class="buttons">
                    <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                        {{$t('save')}}
                    </Button>
                    <Button className="--primary --outline --small" :onclick="back" :class="{spinner: is_saving}">
                        {{$t('back')}}
                    </Button>
                </div>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormGroupTwo from "../form/FormGroupTwo";
import FormInputSelect from "../form/FormInputSelect";
import {required, email} from 'vuelidate/lib/validators'
import Button from "../Button";
import FormInputPassword from "@/components/form/FormInputPassword";
import VehiclesBrandIndexModal from "./VehiclesBrandIndexModal";

export default {
    name: "VehiclesBrandUpdateModal",
    components: {FormInputPassword, Button, FormInputSelect, FormGroupTwo, FormInputText, Form, ModalContainer},
    data() {
        return {
            brand: {
                name: null,
            },
            is_saving: false,
            is_loading_original: false,
        }
    },
    props: {
        brand_id: {
            type: Number,
            required: true,
        }
    },

    validations: {
        brand: {
            name: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('vehicles-brand-update-modal', status);
        },

        back(){
            this.close(false);
            this.$modal.show(
                VehiclesBrandIndexModal, {},
                {
                    name: 'vehicles-brand-index-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                    }
                }
            );
        },

        save() {
            this.$v.brand.$touch();
            if (this.$v.brand.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            const payload = Object.assign({}, this.$v.brand.$model);

            this.$axios.patch(`brands/${this.brand_id}`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('brands.success_updated'),
                    type: 'success',
                });
                this.is_saving = false;

                this.close(false);
                this.$modal.show(
                    VehiclesBrandIndexModal, {},
                    {
                        name: 'vehicles-brand-index-modal',
                        adaptive: true,
                        resizable: true,
                        height: 'auto',
                        scrollable: true,
                        classes: 'modal',
                    }, {
                        'before-close': (e) => {
                        }
                    }
                );
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('brands.error_update')),
                    type: 'error',
                });
            });
        },
        async retrieveOriginalBrand() {
            this.is_loading_original = true;
            await this.$axios.get(`brands/${this.brand_id}`)
                .then(({data}) => {
                    this.is_loading_original = false;
                    this.brand.name = data.data.attributes.name;
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('brands.error_retrieve')),
                        type: 'error',
                    });
                });
        },
    },

    async mounted() {
        await this.retrieveOriginalBrand();
    }
}
</script>

<style lang="scss" scoped>

.buttons {
    @apply flex flex-row justify-between;

    button {
        @apply mx-2 mt-0 px-8 py-3;
    }
}

</style>